import React from "react";

import Breadcrumbs from "@components/common/Breadcrumbs";
import { BreadcrumbUrlPrefixEnum } from "@components/common/Breadcrumbs/types";
import SeoHeaders from "@components/common/seoHeaders";
import { LinkUrlPrefixEnum } from "@components/textPages/types";

import Constructor from "../constructor";
import { getFirstImageFromZone } from "../constructor/utils";

import { LandingProps } from "./types";

import "./styles.scss";

const Landing = ({ pageContext }: LandingProps) => {
  const {
    seoId,
    url,
    title = "Lunaro",
    ogTitle,
    description = "Lunaro",
    breadcrumbDisplayName,
    zone,
    expertsInitial,
  } = pageContext;

  const landingUrl = `${LinkUrlPrefixEnum.Landing}/${url}`;
  return (
    <>
      <SeoHeaders
        title={title}
        ogTitle={ogTitle}
        description={description}
        url={landingUrl}
        imageUrl={getFirstImageFromZone(zone)}
      />
      <div className="landing">
        <div className="page-width">
          <Breadcrumbs
            seoId={seoId}
            url={url}
            breadcrumbDisplayName={breadcrumbDisplayName}
            breadcrumbs={[]}
            urlPrefix={BreadcrumbUrlPrefixEnum.Category}
            showOnPage={false}
          />
          <Constructor zone={zone} expertsInitial={expertsInitial} />
        </div>
      </div>
    </>
  );
};

export default Landing;
